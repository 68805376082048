import React, { useEffect, useState, useRef } from "react"
import Slider from "react-slick"
import { navigate, useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it
import { Box, Container, Flex, Styled, NavLink } from "theme-ui"
import { useIdentityContext } from "react-netlify-identity"
import { ToastContainer, toast } from 'react-toastify';

import { contentSliderProps, imgSliderProps, tokenTypes } from "@const"

import Layout from "@layouts/default"

import ArrowBtn from "@components/ArrowBtn"

import Link from "@common/Link"
import SEO from "@components/seo"

import CalendarIcon from "@assets/calendarIcon.svg"
import CheckMarkIcon from "@assets/checkMarkIcon.svg"
import "slick-carousel/slick/slick.css"
import 'react-toastify/dist/ReactToastify.min.css';

import EventCategories from "@components/EventCategories"

export default (props) => {
  const identity = useIdentityContext()

  const { isConfirmedUser, param, recoverAccount, verifyToken } = identity
  const [state, setState] = useState({ nav1: null, nav2: null })
  const slider1 = useRef()
  const slider2 = useRef()

  !isConfirmedUser &&
    param.type === tokenTypes.signup &&
    verifyToken().then(
      user => {
        console.log("token verified")
        navigate("/");        
      },
      error => console.error("Failed to log in: %o", error)
    )

  isConfirmedUser && props.location.hash && 
    toast.success('Adres email został poprawnie zweryfikowany', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      });

  param.type === tokenTypes.recovery &&
    recoverAccount().then(
      user => navigate("/set-new-password"),
      error => console.error("Failed to log in: %o", error)
    )

  const sliderImgData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "slides/home" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state

  return (
    <Layout>
      <SEO title="Home" />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Flex as="section" variant="layout.homeContainer">
        <Box variant="layout.imgSliderContainer">
          <Slider
            asNavFor={nav2}
            ref={slider => (slider1.current = slider)}
            {...imgSliderProps}
          >
            {sliderImgData.allFile.edges.map(image => {
              return (
                <Box variant="layout.homeImgSlide" key={image.node.base}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    style={{ height: `960px` }}
                    alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                  />
                </Box>
              )
            })}
          </Slider>
        </Box>

        <Container variant="layout.flexColumn">
          <Flex variant="layout.contentSliderContainer">
            <Slider
              asNavFor={nav1}
              ref={slider => (slider2.current = slider)}
              {...contentSliderProps}
            >
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT 2
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT 3
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
            </Slider>
          </Flex>
          <Flex
            sx={{
              flex: "0 156px",
              marginTop: "70px",
              justifyContent: "space-between",
              zIndex: 2,
            }}
          >
            <Flex
              sx={{
                bg: "white",
                borderTopRightRadius: [0, null, null, `78px`],
                paddingRight: [0, null, null, `14rem`],
                flexDirection: [`column`, null, `row`],
                width: [`100%`, null, null, `auto`],
              }}
            >
              <NavLink
                as={Link}
                to="/calendar"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "32px",
                  margin: 0,
                }}
              >
                <CalendarIcon
                  style={{ height: "46px", margin: "25px", width: "46px" }}
                />
                <Flex variant="layout.flexColumn">
                  <Styled.h6>Sprawdź Nasz Kalendarz Eventów</Styled.h6>
                  <Styled.p>
                    i zapisz się na najlepsze wydarzenia branżowe
                  </Styled.p>
                </Flex>
              </NavLink>

              <NavLink
                as={Link}
                to="/login"
                sx={{
                  alignItems: "center",
                  display: "flex",
                  paddingLeft: "32px",
                  margin: 0,
                }}
              >
                <CheckMarkIcon
                  style={{ height: "46px", margin: "25px", width: "46px" }}
                />
                <Flex variant="layout.flexColumn">
                  <Styled.h6>Zaloguj się i bądź na bieżąco</Styled.h6>
                  <Styled.p>
                    Sprawdź co otrzymujesz jako zarejestrowany użytkownik
                  </Styled.p>
                </Flex>
              </NavLink>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Container as="section">
        <EventCategories />
      </Container>
    </Layout>
  )
}
